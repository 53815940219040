// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-betalningar-p-27-js": () => import("./../../../src/pages/betalningar-p27.js" /* webpackChunkName: "component---src-pages-betalningar-p-27-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-edi-js": () => import("./../../../src/pages/edi.js" /* webpackChunkName: "component---src-pages-edi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kundfakturor-js": () => import("./../../../src/pages/kundfakturor.js" /* webpackChunkName: "component---src-pages-kundfakturor-js" */),
  "component---src-pages-kundportal-js": () => import("./../../../src/pages/kundportal.js" /* webpackChunkName: "component---src-pages-kundportal-js" */),
  "component---src-pages-leverantorsfakturor-e-faktura-js": () => import("./../../../src/pages/leverantorsfakturor/e_faktura.js" /* webpackChunkName: "component---src-pages-leverantorsfakturor-e-faktura-js" */),
  "component---src-pages-leverantorsfakturor-fakturakontroll-js": () => import("./../../../src/pages/leverantorsfakturor/fakturakontroll.js" /* webpackChunkName: "component---src-pages-leverantorsfakturor-fakturakontroll-js" */),
  "component---src-pages-leverantorsfakturor-index-js": () => import("./../../../src/pages/leverantorsfakturor/index.js" /* webpackChunkName: "component---src-pages-leverantorsfakturor-index-js" */),
  "component---src-pages-leverantorsfakturor-papper-pdf-js": () => import("./../../../src/pages/leverantorsfakturor/papper_pdf.js" /* webpackChunkName: "component---src-pages-leverantorsfakturor-papper-pdf-js" */),
  "component---src-pages-leverantorsfakturor-skanning-js": () => import("./../../../src/pages/leverantorsfakturor/skanning.js" /* webpackChunkName: "component---src-pages-leverantorsfakturor-skanning-js" */),
  "component---src-pages-nyhet-js": () => import("./../../../src/pages/nyhet.js" /* webpackChunkName: "component---src-pages-nyhet-js" */),
  "component---src-pages-nyheter-js": () => import("./../../../src/pages/nyheter.js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-pdf-policy-informationssakerhetspolicy-js": () => import("./../../../src/pages/pdf/policy/informationssakerhetspolicy.js" /* webpackChunkName: "component---src-pages-pdf-policy-informationssakerhetspolicy-js" */),
  "component---src-pages-pdf-policy-kvalitetspolicy-js": () => import("./../../../src/pages/pdf/policy/kvalitetspolicy.js" /* webpackChunkName: "component---src-pages-pdf-policy-kvalitetspolicy-js" */),
  "component---src-pages-pdf-policy-miljopolicy-js": () => import("./../../../src/pages/pdf/policy/miljopolicy.js" /* webpackChunkName: "component---src-pages-pdf-policy-miljopolicy-js" */),
  "component---src-pages-pdf-villkor-anvandarvillkor-js": () => import("./../../../src/pages/pdf/villkor/anvandarvillkor.js" /* webpackChunkName: "component---src-pages-pdf-villkor-anvandarvillkor-js" */),
  "component---src-pages-pdf-villkor-integritetspolicy-js": () => import("./../../../src/pages/pdf/villkor/integritetspolicy.js" /* webpackChunkName: "component---src-pages-pdf-villkor-integritetspolicy-js" */),
  "component---src-pages-sdk-js": () => import("./../../../src/pages/sdk.js" /* webpackChunkName: "component---src-pages-sdk-js" */),
  "component---src-pages-splash-js": () => import("./../../../src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-sv-index-js": () => import("./../../../src/pages/sv/index.js" /* webpackChunkName: "component---src-pages-sv-index-js" */),
  "component---src-pages-sv-kundsupport-js": () => import("./../../../src/pages/sv/kundsupport.js" /* webpackChunkName: "component---src-pages-sv-kundsupport-js" */),
  "component---src-pages-vilka-vi-ar-js": () => import("./../../../src/pages/vilka-vi-ar.js" /* webpackChunkName: "component---src-pages-vilka-vi-ar-js" */),
  "component---src-pages-webinar-js": () => import("./../../../src/pages/webinar.js" /* webpackChunkName: "component---src-pages-webinar-js" */)
}

